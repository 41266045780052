/*
 * CUSTOM JAVASCRIPT
 *
 * All of your custom javascript can be placed below.
 * Do NOT forgot to add comments above the different functions or functionalities that need explaining.
 *
 */

/**
 * Selector caching
 *
 * @type {jquery object}
 */
var $document = $(document);
var $window = $(window);
var $body = $('body');

/**
 * Get token from meta tag in header and add CSRF token to the ajax request
 * @author Rick van der Burg <rick@click.nl>
 */
function prepareForAjax(){
    $.ajaxSetup({
        headers:
        {
            'X-CSRF-Token': $('meta[name="_token"]').prop('content')
        }
    });
}

prepareForAjax();

/**
 * Global function for showing an alert box
 * @author Arjan Oosthoek
 * @param  string heading
 * @param  string custom_html
 * @return string
 */
function alertBoxHtml( heading, custom_html ){
    // define html for alert box
    var html = '<div class="alert-box js-alert-box">';
    html += '<a href="#" class="close js-close">×</a>';
    html += '<strong>' + heading +'</strong><br>';
    html += custom_html + '.<br></div>';

    // insert the generated html in the alert-holder
    $('.js-alert-holder').html( html );
}

/**
 * Measure window width
 * Fallback for iOS: returns 0 when window.outerWidth is being used
 * Fallback for Safari: measures media query window width through document.documentElement.clientWidth
 *
 * @author Rianne Oosthoek
 */
function getWindowWidth(){
    if( $body.hasClass('is-touch') ){
        return $window.width();
    }else if( $body.hasClass('is-desktop') ){
        return window.outerWidth;
    }
}

/**
 * Get value of given breakpoint based on Sass breakpoints
 *
 * @author Rianne Oosthoek
 * @param  {string} name of the breakpoint (small, medium, etc.)
 * @return {int}    breakpoint size in pixels
 */
function getBreakpointSize( size ){
    // Loop through Foundation breakpoints
    for( var i in Foundation.MediaQuery.queries ) {
        var query = Foundation.MediaQuery.queries[i];
        if( size === query.name ){
            // Split media query string so only the em value remains
            var str = query.value;
            var split_str = str.split('min-width: ')[1];
            // Split string and parse em value to and get px value
            var query_value = ( parseFloat( split_str.split('em)')[0] ) * 16 );

            return query_value;
        }
    }
}

/**
 * Functionality for main and side menu
 *
 * @author Rianne Oosthoek
 */
var menu = function(){
    var self = this;
    var window_width;
    var $menu_icon = $('.js-menu-icon');
    var $menu_anchor = $('.js-menu-anchor');
    var $off_canvas_exit = $('.js-off-canvas-exit');

    self.init = function(){
        self.closeSideMenuOnDesktop();
        // Toggle menu icon animation 
        $menu_icon.click(function(){
            $(this).addClass('is-open');
            $body.addClass('is-disabled-scrolling');
        });
        $off_canvas_exit.click(function(){
            $menu_icon.removeClass('is-open');
            $body.removeClass('is-disabled-scrolling');
        });

        // Active state menu
        self.setListenerMenu();

        // Flyout for language choice
        self.setListenerDesktopLanguage();
    };
    /**
     * Close side menu when reaching desktop breakpoint
     *
     * @author Rianne Oosthoek
     */
    self.closeSideMenuOnDesktop = function() {
        window_width = getWindowWidth();

        $window.on('orientationchange resize', function(){
            window_width = getWindowWidth();

            // Close side menu when entering large screensize
            if( window_width > getBreakpointSize('large') && $off_canvas_exit.hasClass('is-visible') ){
                $off_canvas_exit.trigger('click');
            }
        });
    };
    /**
     * Set click listener on menu item for functionality on touch devices
     *
     * @author Rianne Oosthoek
     */
    self.setListenerMenu = function(){
        $menu_anchor.click(function(e){
            // Determine which level <li> we're in
            var current_level = parseInt( $(this).parent('.js-menu-item').attr('data-level') );
            // Get the parent <li> of $menu_anchor
            var $menu_item = $(this).parent('.js-menu-item');
            // Retrieve the submenu of current_level
            var $submenu = $(this).next('.js-submenu[data-submenu=' + current_level + ']');

            // Is it the main menu?
            if( current_level === 1 ){
                // Remove all active classes from all other levels and submenus
                $('.js-menu-item').not( $menu_item ).removeClass('is-active');
                $('.js-submenu').not( $submenu ).removeClass('is-open');
                // Toggle active class of current menu item
                $menu_item.toggleClass('is-active');
                // Does it have a submenu-1 element?
                if( $submenu.length > 0 ){
                    // Disable default click
                    e.preventDefault();
                    $submenu.toggleClass('is-open');
                }
            // Is it the first submenu?
            }else if( current_level === 2 ){
                // Find the parent element
                var $level_1 = $(this).parents('.js-menu-item.level-1');
                // Find the children of the parent element so we can remove the active states of those children
                $level_1.find('.js-menu-item').not( $menu_item ).removeClass('is-active');
                $level_1.find('.submenu-2').not( $submenu ).removeClass('is-open');

                // Toggle active class of current menu item
                $menu_item.toggleClass('is-active');
                if( $submenu.length > 0 ){
                    // Disable default click
                    e.preventDefault();
                    // Toggle class ( open submenu-2 )
                    $submenu.toggleClass('is-open');
                }
            // Is it the second submenu?
            }else if( current_level === 3 ){
                // Find the parent elements
                var $level_1 = $(this).parents('.js-menu-item.level-1');
                // Find the child element of the parent so we can remove the active state of that child
                $level_1.find('.js-menu-item.level-3').not( $menu_item ).removeClass('is-active');
                // Toggle active class of current menu item
                $menu_item.toggleClass('is-active');
            }
        });

        // Close all items when clicked outside of the menu
        $document.click( function(e) {
            var $menu_anchor = $('.js-menu-anchor');
            var $submenu = $('.js-submenu');
            // Clicked element isn't a menu anchor or a submenu element
            if( !$menu_anchor.is( e.target ) &&
                !$submenu.is( e.target) ){ 
                // Close all other tasks
                $('.js-submenu').removeClass('is-open');
                // Slide up all task-info
                $('.js-menu-item').removeClass('is-active');
            }
        });
    };
    /**
     * Set listener on language selection flyout menu
     *
     * @author Rianne Oosthoek
     */
    self.setListenerDesktopLanguage = function(){
        var $btn_lc = $('.js-btn-lc');
        var $lc_wrapper = $('.js-lc-inner-wrapper');
        var $lc_flyout = $('.js-lc-flyout');

        // Check if we're on a desktop or touch device
        if( $body.hasClass('is-desktop') ){
            $lc_wrapper.hover(function(){
                // Add active class to language flyout
                $(this).addClass('is-active');
                $lc_flyout.addClass('is-open');
            }, function(){
                // Remove active class from language flyout
                $(this).removeClass('is-active');
                $lc_flyout.removeClass('is-open');
            });
        }
    };
};

/**
 * Makes the image on the homepage full screen
 *
 * @author Rianne Oosthoek
 */
var full_image = function(){
    var self = this;
    var window_height;
    var header_height;
    var full_image_height;
    var $full_image_wrapper = $('.js-full-image-wrapper');

    self.init = function(){
        $window.on('load resize orientationchange', function(){
            window_height = $window.height();
            header_height = $('.js-header-wrapper').height();
            full_image_height = window_height - header_height;
            
            // Set full_image_wrapper height so it will be full screen
            $full_image_wrapper.css('height', full_image_height);
        });
    };
};

/**
 * Scroll down to the next element after clicking 
 * the button in the header
 *
 * @author Rianne Oosthoek
 */
var scroll_to_next_element = function(){
    var self = this;
    var $scroll_trigger = $('.js-trigger-scroll-down');

    self.init = function(){
        $scroll_trigger.click(function(){
            $('html, body').animate({
                scrollTop: $('.js-content').offset().top
            }, 1000);
        });
    };
};

/**
 * Scroll to reservation form on product detail page
 *
 * @author Rianne Oosthoek
 */
var scroll_to_reserve_form = function(){
    var self = this;

    self.init = function(){
        var $btn_reserve = $('.js-reserve');

        // Scroll to reserve for whe triggering reserve button
        $btn_reserve.click(function(){
            $('html, body').animate({
                scrollTop: $('.js-reservation-form').offset().top
            }, 800);
        });
    };

    self.triggerClick = function(){
        $('.js-reserve').trigger('click');
    }
};

/**
 * Slider functionality
 *
 * @author Rianne Oosthoek
 */
var slider = function(){
    var self = this;
    var $slider = $('.js-slider');
    var $slider_thumbs = $('.js-slider-thumbs')

    self.setSliderWithArrows = function(){
        $slider.each(function(){
            $(this).slick({
                infinite : true,
                dots : true,
                autoplay : true,
                speed : 1000,
                prevArrow : '<button type="button" class="slider-arrow arrow-prev"><span class="icon icon-arrow-left"></span></button>',
                nextArrow : '<button type="button" class="slider-arrow arrow-next"><span class="icon icon-arrow-right"></span></button>'
            });
        });
    };

    self.setSliderWithoutArrows = function(){
        $slider.each(function(){
            $(this).slick({
                infinite : true,
                dots : true,
                autoplay : true,
                speed : 1000,
                arrows: false
            });
        });
    };

    self.setSliderWithCustomThumbs = function(){
        $slider_thumbs.on('init', function(event, slick){
            var $article_thumbs = $('.js-article-thumbs');
            // Adds classes to the thumbs
            $article_thumbs.addClass('small-up-2 medium-up-4 article-thumb-wrapper row');
            $article_thumbs.children('li').addClass('column');
        }).slick({
            infinite : false,
            arrows : false,
            dots : true,
            speed: 1000,
            dotsClass: 'js-article-thumbs',
            customPaging : function(slider, i) {
                // Replaces dots for thumbs
                var thumb = $(slider.$slides[i]).data('thumb');
                return '<img src="' + thumb + '" class="thumb">';
            }
        });
    };
};

/**
 * Animates menu
 *
 * @author Rianne Oosthoek & Mannus van der Meer
 * @credits
 */
var scroll_spy = function(){
    var self = this;
    var $header = $('.js-header-wrapper');
    var $main_menu = $('.js-main-menu-wrapper');
    var $menu_anchor = $('.js-menu-anchor');

    self.init = function(){
        // Previous scroll position
        var prev_scroll = 0;
        // Needed to add and remove logo from fixed menu just once
        var is_fixed_menu = false;
        var set_logo = false;

        $window.scroll(function(){
            // Determine the scroll position
            var current_scroll = $window.scrollTop();

            // When you haven't scrolled past the main nav
            if ( current_scroll < ( $header.height() + $main_menu.height() ) ){
                $main_menu.removeClass('is-up is-down is-fixed');

                // Reset previous scroll position
                prev_scroll = current_scroll;
                is_fixed_menu = false;
            // When scrolled past the main nav and the scroll direction is down, hide menu
            }else if( prev_scroll < current_scroll ){
                $main_menu.addClass('is-up is-fixed');
                $main_menu.removeClass('is-down');

                // Reset previous scroll position
                prev_scroll = current_scroll;
                is_fixed_menu = true;
            // When scrolled past the main nav and the scroll direction is up, show menu
            }else if( prev_scroll > current_scroll ){
                $main_menu.addClass('is-down is-fixed');
                $main_menu.removeClass('is-up');

                // Reset previous scroll position
                prev_scroll = current_scroll;
                is_fixed_menu = true;
            }

            // Show logo or home text in menu (execute just once)
            if( is_fixed_menu && !set_logo ){
                $menu_anchor.filter('[data-menu-item="home"]').html( $('.logo-wrapper').html() );
                set_logo = true;
            }else if( !is_fixed_menu && set_logo ){
                $menu_anchor.filter('[data-menu-item="home"]').html( Lang.get('frontend.js.home') );
                set_logo = false
            }

        });
    };
};

/**
 * Functionality for (automatically) removing alert boxes
 *
 * @author Rianne Oosthoek
 */
var alert_box = function(){
    var self = this;

    var $alert_box = $('.js-alert-box');

    self.init = function(){
        // Fade out and remove alert box after clicking on close btn
        $body.on('click', '.js-close', function(e){
            e.preventDefault();
            self.removeAlertBox( true, $(this) );
        });

        // Fade out and remove alert box automatically after x seconds 
        if( $alert_box.hasClass('success') ){
            setTimeout(function(){
                self.removeAlertBox( false );
            }, 10000);
        }
    }

    //  Method for removing the alert box
    self.removeAlertBox = function( click_event, $close_btn ){
        // Does a click event take place?
        if( click_event ){
            $close_btn.parent( $alert_box ).addClass('alert-close');
        }else{
            $alert_box.addClass('alert-close');
        }
        // Remove alert box as soon as transition is done
        $body.on('transitionend webkitTransitionEnd oTransitionEnd msTransitionEnd', '.js-alert-box', function () {
            $(this).remove();
        });
    }
};

/**
 * Handle select all functionality for favorite products
 *
 * @author Dennis Bruinen & Arjan Oosthoek
 */
var favorite = function(){
    var self = this;
    var $icon_favorite = $('.js-icon-favorite');
    self.expire_in_days = 365;
    self.cookie_name = 'deopkamer_favorites';

    self.init = function(){
        self.initListenerAddToFavorites();
    };

    /**
     * When products are loaded, we want to see which have been favorited before
     * @author Dennis Bruinen & Arjan Oosthoek
     */
    self.initListenerUpdateFavoriteButtonsInOverview = function(){
        // We need to activate the favorites that have been added to the cookie before, when the results are loaded
        $body.on('products-loaded', function(){
            $('.js-add-favorite').each(function(){
                var $button = $(this);
                // Get the id
                var product_id = $button.attr('data-id');

                // Check if cookie already exists
                if( Cookies.get( self.cookie_name ) !== undefined ){
                    var cookie_data = Cookies.get( self.cookie_name );
                    var arr_ids = cookie_data.split(',');

                    // Check if this id has been added to the cookie before
                    if( $.inArray( product_id, arr_ids ) > -1 ){
                        // Activate link
                        $button.addClass('is-added');
                    }
                }
            });
        });
    }    

    /**
     * Method for updating the favorite buttons on detailpage
     *
     * @author Dennis Bruinen
     * @param  String offer_type
     * @param  Int offer_id
     */
    self.updateFavoriteButtonsInDetail = function( product_id ){
        // Check if cookie already exists
        if( Cookies.get( self.cookie_name ) !== undefined ){
            var cookie_data = Cookies.get( self.cookie_name );
            var arr_ids = cookie_data.split(',');
            // Check if this id has been added to the cookie before
            if( $.inArray( product_id, arr_ids ) > -1 ){
                $('.js-add-favorite').addClass('is-added');
            }
        }

    }

    // called in the master
    self.initListenerAddToFavorites = function(){
        $body.on('click', '.js-add-favorite', function(){
            var current_url = window.location.pathname;

            var $button = $(this);
            // get the id
            var product_id = $button.attr('data-id');

            if( product_id !== 'undefined'){
                if( Cookies.get( self.cookie_name ) !== undefined ){
                    var cookie_data = Cookies.get( self.cookie_name );
                    if( cookie_data !== '' ){
                        var arr_ids = cookie_data.split(',');
                    } else {
                        var arr_ids = [];
                    }
                    var current_length = arr_ids.length;
                    // check if id is in cookie
                    if( $.inArray( product_id, arr_ids ) > -1 ){
                        // already found in array, remove it
                        arr_ids.splice( $.inArray( product_id, arr_ids ), 1 );
                        // check if the current page is 'favorites'
                        if( current_url.indexOf('favoriten') !== -1 || current_url.indexOf('favorites') !== -1 || current_url.indexOf('favorieten') !== -1 ){
                            $('.js-article').addClass('undo-placeholder');
                        }
                        // remove class from button
                        $button.removeClass('is-added');
                        // no more ids in cookie? remove it
                        if( current_length -1 === 0 ){
                            Cookies.remove( self.cookie_name );
                        } else {
                            // set the new value to the cookie
                            Cookies.set( self.cookie_name, arr_ids.join(','), { expires: self.expire_in_days } );
                        }
                    } else {
                        // not found in array, add it
                        arr_ids.push( product_id );
                        // check if the current page is 'favorites'
                        if( current_url.indexOf('favoriten') !== -1 || current_url.indexOf('favorites') !== -1 || current_url.indexOf('favorieten') !== -1 ){
                            $('.js-article').removeClass('undo-placeholder');
                        }
                        // add class to button
                        $button.addClass('is-added');
                        // set the new value to the cookie
                        Cookies.set( self.cookie_name, arr_ids.join(','), { expires: self.expire_in_days } );

                        // Set pulse animation on heart icon in menu
                        $icon_favorite.addClass('is-pulse');
                        // Remove class when animation has ended
                        $icon_favorite.on('webkitAnimationEnd oanimationend oAnimationEnd msAnimationEnd animationend', function(){
                        $icon_favorite.removeClass('is-pulse');
                    });
                    }
                } else {
                    // Cookie doesn't exist yet, create it
                    Cookies.set( self.cookie_name, product_id, { expires: self.expire_in_days } );
                    // check if the current page is 'favorites'
                    if( current_url.indexOf('favoriten') !== -1 || current_url.indexOf('favorites') !== -1 || current_url.indexOf('favorieten') !== -1 ){
                            $('.js-article').removeClass('undo-placeholder');
                        }
                    // add class to button
                    $button.addClass('is-added');
                }
                // update counter();
                self.updateBadgeCounter();
            }
        });
    }

    /**
     * Get the data from the cookie and show it (only called on the favorites page)
     * @author Arjan Oosthoek
     * @param  {string} token   csrf_token
     * @param  {string} lc      current language
     * @return {json}           
     */
    self.initFavorites = function( lc ){
        // get all favorites from cookie
        var arr_ids = [];
        if( Cookies.get( self.cookie_name ) !== undefined ){
            var cookie_data = Cookies.get( self.cookie_name );
            arr_ids = cookie_data.split(',');
        }

        // prepare data for ajax call
        var data = {};
        data.arr_ids = arr_ids;

        // perform ajax call to try to save the posted data
        $.ajax({
            type: 'POST',
            url: '/ajax/post-favorites',
            data: data,
            async: true
        }).done( function( json ){
            if( json.status === 'OK' ){
                var something_to_show = false;

                // handle active favorites
                if( json.html_active_products !== '' ){
                    $('.js-active-favorites').html( json.html_active_products );
                    $('.js-active-favorites-holder').removeClass('hide');
                    something_to_show = true;
                }

                // handle inactive favorites
                if( json.html_inactive_products !== '' ){
                    $('.js-inactive-favorites').html( json.html_inactive_products );
                    $('.js-inactive-favorites-holder').removeClass('hide');
                    something_to_show = true;   
                }
                // determine what we want to show (results, or no favorites message )
                if( something_to_show ){
                    $('.js-favorite-holder').removeClass('hide');
                } else {
                    $('.js-no-favorites').removeClass('hide');
                }
                
                var t = setTimeout( function(){
                    // remove the preloader icon
                    $('.js-preloader-wrapper').remove();
                    $body.trigger('products-loaded');
                }, 250);


            } else {
                alert( Lang.get('backend.js.something_went_wrong'));
            }
        });
    }

    /**
     * Method to update the favorites counter
     * @author Dennis Bruinen & Arjan Oosthoek
     */
    self.updateBadgeCounter = function( ){
        self.checkAndUpdateCookies( );
    }

    /**
     * Method to check if the cookie is still up to date
     * @author Dennis Bruinen & Arjan Oosthoek
     * @return {json} 
     */
    self.checkAndUpdateCookies = function( ){
        var arr_ids = [];
        if( Cookies.get( self.cookie_name ) !== undefined ){
            var cookie_data = Cookies.get( self.cookie_name );
            arr_ids = cookie_data.split(',');
        }

        // get all favorites from cookie
        var data = {};
        data.arr_ids = arr_ids;

        // perform ajax call and check the cookie data
        $.ajax({
            type: 'POST',
            url: '/ajax/post-check-favorites',
            data: data,
            async: true
        }).done( function( json ){
            var total = 0;

            // if not all products are published
            if( !json.all_products_published ){
                // set he new value to the cookie
                Cookies.set( self.cookie_name, json.new_product_ids.join(','), { expires: self.expire_in_days } );
            }

            // count total, if cookie is defined
            if( Cookies.get( self.cookie_name ) !== undefined ){
                var cookie_data = Cookies.get( self.cookie_name );
                // if cookie isn't an empty string
                if( cookie_data !== '' ){
                    var arr_ids = cookie_data.split(',');
                    total += arr_ids.length;
                }
            }

            // update the text of the badge in the menu
            $('.js-favorite-badge').text( total );
        });
    }
};

/**
 * Method to handle the post of the quotation form
 * @author Arjan Oosthoek
 */
var quotation_form = function(){
    var self = this;
    self.cookie_name = 'deopkamer_favorites';

    self.init = function(){
        self.listenerFormSubmit();
    }

    // listener for form submit button
    self.listenerFormSubmit = function(){
        $body.on('click', '.js-submit-form', function(e){
            e.preventDefault();
            // check if the current url contains 'favorites'
            var current_url = window.location.pathname;
            if( current_url.indexOf('favoriten') !== -1 || current_url.indexOf('favorites') !== -1 || current_url.indexOf('favorieten') !== -1 ){
                // it does, check the cookie for ids
                if( Cookies.get( self.cookie_name ) !== undefined && Cookies.get( self.cookie_name ) !== '' ){
                    var cookie_data = Cookies.get( self.cookie_name );
                    var arr_ids = cookie_data.split(',');
                    $('.js-product-ids').val( arr_ids );
                    // set a small delay for the form to be submitted
                    setTimeout( function(){
                        $('.js-form').submit();
                    },250);
                } else {
                    // the cookie is empty or doesn't exist, display a message
                    alertBoxHtml( Lang.get('frontend.js.form_submit_no_products_heading'), Lang.get('frontend.js.form_submit_no_products_body') );
                }
            } else {
                // the current url doesn't contain 'favorites' so we're on a product detail page > submit the form
                $('.js-form').submit();
            };
        });
    }
};

/**
 * Show the captcha-holder when a user changes the values of a form
 *
 * @author Dennis Bruinen
 */
var captcha_toggle = function(){
    var self = this;

    self.init = function( $form ){
        var serialized_form = $form.serialize();

        var is_show_captcha = false;
        // check if one of the field already has a value, then it has been posted before, so show the captcha
        $form.find('input,textarea').not('[type=hidden]').each(function(){
            if( $(this).val() !== '' ){
                is_show_captcha = true;
                if( !$(this).hasClass('never') ){
                    $(this).addClass('valid');
                }
            }
        });

        if( is_show_captcha ){
            // show captcha
            self.showCaptcha();
        }

        // set listener on contact form
        $form.find('input,textarea,select').bind('change keyup input', function(){
            // check if form has changed and add/remove the class valid
            if( $(this).val() ){
                if( !$(this).hasClass('valid') ){
                    $(this).addClass('valid');
                }
            }else{
                if( $(this).hasClass('valid') ){
                    $(this).removeClass('valid');
                }
            }

            // check if form has chang  ed
            if( serialized_form != $form.serialize() ){
                self.showCaptcha();      
            }
        });

        // hide submit button when user submits
        // TODO
     }

    self.showCaptcha = function( ){
        // show captcha check
        $('.js-captcha-holder').addClass('visible captcha');
        
    }

    self.placeholder = function( $form ){

        // check if one of the field already has a value, then it has been posted before, so show the captcha
        $form.find('input,textarea,select').each(function(){
            if( $(this).val() !== '' ){
                if( !$(this).hasClass('never') ){
                    $(this).addClass('valid');
                }
            }
        });

        $form.find('input,textarea,select').bind('change keyup input', function(){
            // check if form has changed and add/remove the class valid
            if( $(this).val() && !$(this).hasClass('never') ){
                if( !$(this).hasClass('valid') ){
                    $(this).addClass('valid');
                }
            }else if( !$(this).hasClass('never') ){
                if( $(this).hasClass('valid') ){
                    $(this).removeClass('valid');
                }
            }
        });
    }
};

/**
 * Set footer fixed on bottom of the document in certain situations
 *
 * @author Rianne Oosthoek
 */
var fixed_footer = function(){
    var self = this;

    self.init = function(){
        var $layout = $('.js-layout');
        var $footer = $('.js-footer-wrapper');
        var page_height;

        $window.on('load resize orientationchange', function(){
            page_height = $layout.outerHeight() + $footer.outerHeight();
            // Page height is larger than window height
            if( page_height > $window.height() ){
                if( $footer.hasClass('is-fixed-bottom') ){
                    // Unset fixed footer
                    $footer.removeClass('is-fixed-bottom');
                }
            }else{
                if( !$footer.hasClass('is-fixed-bottom') ){
                    // Set fixed footer
                    $footer.addClass('is-fixed-bottom');
                }
            }
        });
    }
};

/**
 * Functionality to display more items on the product, realisation, about us (who are we) and export overview page
 * @author Jerry Trimp
 * @return {json}
 */
var show_more_items = function(reload_sliders){
    var self = this;
    var $item_overview = $('.js-items');
    var $show_more_button = $('.js-show-more-items');
    var url = $show_more_button.data('url');

    self.init = function(){
        // when clicked on show more button
        $show_more_button.on('click', function(e){
            e.preventDefault();

            //only the products overview page has a subsubcategory id, for export, about us (who are we) en realisation pages this must be an empty string in the html data attribute
            var subsubcategory_id = $(this).data('subsubcategory-id');
            $show_more_button.attr('disabled', 'disabled');

            // count current amount of items
            var count = $('.js-article').length;
         
            $.ajax({
                url: url,
                data: {count: count, subsubcategory_id: subsubcategory_id},
                success: self.handleMoreItems
            });
        });
    }

    self.handleMoreItems = function( data ){
        $show_more_button.removeAttr('disabled');   
        $item_overview.append( data.items );

        //the content that is added to the page has one or more sliders, therefore the sliders must be re-initialized, 
        //otherwise the newly added slider will show the images underneath eachother
        if(reload_sliders === true){
            var multiple_sliders_on_page_func =  new multiple_sliders_on_page();
            var overlay_slideshow_func =  new overlay_slideshow();

            //attach new lightcases to the items that have been added
            overlay_slideshow_func.init();

            //remove old listeners
            multiple_sliders_on_page_func.destroy();

            //attach new listeners
            multiple_sliders_on_page_func.init();
        }

        // when the total amount of items has been reached
        if( !data.load_more_button ){
            $show_more_button.addClass('hide');
        }
    }
};

/**
 * Functionality for lightcase on product images
 *
 * @author Rianne Oosthoek
 */
var overlay_slideshow = function(){
    var self = this;

    self.init = function(){
        //only do uninitialized lightcase images, because when a user loads more items, you dont want the existing ones to be lightcased again
        $('.js-lightcase-slideshow').not('.initialized').lightcase({
            autoSlideshow: false,
            transition: 'scrollHorizontal',
            speedIn: 600,
            speedOut: 600,
            maxWidth: 1400,
            maxHeight: 1020,
            showSequenceInfo: false,
            showTitle: false,
            showCaption: false,
            shrinkFactor: 0.90,
            onInit: {
                removeDataRel: function(){
                    // Remove all the data-rel attributes on cloned slick items, so no double items will appear in the lightcase popup
                    $('.slick-cloned > a').removeAttr('data-rel');
                }
            }
        });


        //add the initialized class to the lightcases that have been added already,
        //so that later on we can differentiate between initialized and not yet initialized lightcases
        $('.js-lightcase-slideshow').addClass('initialized');
    };
};

/**
 * Makes a url to the external Google Map to show the directions from the user's inputted address to the De Opkamer store in Made
 *
 * @author Jerry Trimp
 */
var show_directions_on_external_google_maps = function(){
    var self = this;

    self.init = function(){

        //when a user clicks the button
        $('.show-directions-button').on('click',function(){

            //get the content of the input field in which the user can input his / her start location
            var users_start_location = $('.users-start-location-input').val();

            //check if the user filled the input field
            if(users_start_location.length > 0){

                // parse the users_start_location so that is replaces the spaces by plus signes (although spaces will work in this url, plus signs are safer)
                var users_parsed_start_location = users_start_location.replace(" ", "+");

                var destination_location = 'haagstraat+8+4921xa+made+the+netherlands';
                var google_maps_url = 'https://maps.google.com/?saddr='+users_parsed_start_location+'&daddr='+destination_location;

                //open the url in a new browser window
                var external_google_maps_window = window.open(google_maps_url, '_blank');
                external_google_maps_window.focus();
            }else{
                //show the error message
                $('.directions-form').find('.error').show();
            }

        });
    };
    
};
/**
 * this function is used once a page contains multiple sliders, to make sure that only 1 will autoslide at a time
 * @author  Jerry Trimp
 * @return void
 */
var multiple_sliders_on_page =  function(){
    var self = this;
    var $sliders_on_page = $('.js-slider');

    self.init = function(){
        var $first_slider;
        var i = 0;
        var is_autoplaying;

        $sliders_on_page.each(function(){

            //check if it is the first slider
            if(i === 0){
                is_autoplaying = true;
                $first_slider = $(this);
            }else{
                is_autoplaying = false;
            }

            //init slider
            $(this).slick({
                infinite : true,
                dots : true,
                autoplay : is_autoplaying,
                speed : 1000,
                prevArrow : '<button type="button" class="slider-arrow arrow-prev"><span class="icon icon-arrow-left"></span></button>',
                nextArrow : '<button type="button" class="slider-arrow arrow-next"><span class="icon icon-arrow-right"></span></button>'
            });

            /**
             * When a slider is hovered, 
             * @param  this first function is triggered when the user's cursor enters the slider (onmouseenter), it starts the autoplay feature of the slider
             * @param  this second function is triggered when the user's cursor leaves the slider (onmouseleave), it stops the autoplay feature of the slider
             * @return void
             * @author Jerry Trimp
             */
            $(this).hover(
            function(){
                //play the current slide show
                $(this).slick('slickPlay');
                
                //stop the first slideshow, if the one you're hovering over is not the first slide show
                if(!$(this).is($first_slider)){
                    $first_slider.slick('slickPause');
                }
            }, 
            function(){
                 //stop the first slideshow, if the one you're hovering over is not the first slide show
                if(!$(this).is($first_slider)){
                    //stop the current slide show
                    $(this).slick('slickPause');
                }

                //start the first slide show
                $first_slider.slick('slickPlay');
            });

            i++;
        });
    }

    //removes all previously added event handlers and unslicks all by slick initialized sliders, this gets called by the show_more_items function
    self.destroy = function(){
        var $initialized_sliders = $('.js-slider.slick-initialized');

        $initialized_sliders.each(function(){
            //unslick all elements
            $(this).slick('unslick');

            //removes all event handlers
            $(this).off();
        });
    }

};

/**
 * This function shows the homepage popup, 
 * and is executed when the view determines that the page content item for the popup is available and the user hasnt seen the popup yet
 * @author  Jerry Trimp
 */
var show_homepage_popup = function(){
    var self = this;
    var $popup = $('#header-popup');

    self.init = function(){
        if(!$popup.length)
            return;

        $popup.foundation('open');
        //when the modal gets closed
        //triggered when a user clicks one of the close buttons or closes the modal in an other way (press esc key, click outside the modal)
        $popup.on('closed.zf.reveal', function(){
            $.ajax({
                url: 'ajax/set-user-saw-homepage-popup'
            });
        });
    }
};